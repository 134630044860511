import styled from 'styled-components';
import { StyleContainer, Title } from '@components';
import { theme } from '@theme/theme';

export const Column = styled(StyleContainer)`
  width: 100%;
  position: relative;
  align-items: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    align-items: flex-start;
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    width: 50%;
  }
`;

export const ColorModifier = styled.span`
  color: ${theme.colors.blue};
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column !important;
  align-items: center;
  div {
    justify-content: center;
    align-items: center;
  }
  p,
  h2 {
    text-align: center;
  }
  @media (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row !important;
    align-items: flex-start;
    div {
      justify-content: flex-start;
      align-items: flex-start;
    }
    p,
    h2 {
      text-align: start;
    }
  }
`;

export const WidthModifier = styled.span`
  display: flex;
  justify-content: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    justify-content: flex-start;
  }
  h1 {
    max-width: 400px;
  }
`;

export const CustomTitle = styled(Title)`
  text-align: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    text-align: start;
  }
`;

export const ImageServices = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  @media (min-width: ${theme.breakpoints.lg}) {
    display: block;
  }
`;
