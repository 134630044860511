import React from 'react';
import {
  Section,
  Text,
  Container,
  BulletItem,
  Title,
  SmallText,
  Button,
} from '@components';

import background from '@assets/imgs/background-steps.png';
import { bulletItems } from './settings';

import { WidthModifier } from './styles';

export const StepsSection = () => (
  <Section
    full
    padding="regular"
    direction="column"
    backgroundLg={background}
    position="right bottom"
    size="contain"
  >
    <WidthModifier>
      <Title color="white" weight="semibold" bottom="3">
        Desenvolver um software é complicado, nós descomplicamos para você!
      </Title>
      <Text weight="bold">
        Você apresenta sua ideia e pode deixar nas mãos de <br /> quem sabe como
        fazer. <br />
        Desenvolvemos seu app em 4 passos:
      </Text>
    </WidthModifier>
    <Container margin={'5 0'} responsiveDirection direction="column">
      {bulletItems.map((item, key) => (
        <BulletItem
          key={`${item.title}-bullet`}
          id={key + 1}
          weight="bold"
          direction="row"
          outerPadding={'3 3 0 0'}
          innerPadding={'0 3'}
          title={item.title}
          small
        >
          <SmallText color="whiteMate" lineHeight="1.35">
            {item.content}
          </SmallText>
        </BulletItem>
      ))}
    </Container>
    <WidthModifier maxW="500px">
      <Text top="3" bottom="5" weight="bold">
        Tenha sua orientação gratuita agora com um dos especialistas da
        SevenApps:
      </Text>
      <a href="#contactSection">
        <Button text="Fazer consultoria gratuita para o meu projeto!" />
      </a>
    </WidthModifier>
  </Section>
);
