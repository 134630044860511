import phoneIcon from '@assets/icons/icon-phone.svg';
import mailIcon from '@assets/icons/icon-mail.svg';
import addressIcon from '@assets/icons/icon-address.svg';

export const contactData = [
  {
    icon: phoneIcon,
    title: 'Celular',
    text: '(31) 9 8987-4925',
  },
  {
    icon: mailIcon,
    title: 'E-mail',
    text: 'contato@sevenapps.com.br',
  },
  {
    icon: addressIcon,
    title: 'Endereço',
    text: 'Av do contorno, 927 - São Paulo',
  },
];
