import styled from 'styled-components';
import { CustomizeText } from '@components';
import { theme } from '@theme/theme';

export const WidthModifier = styled.span`
  max-width: ${({ maxW }) => maxW || '800px'};
  text-align: center;
`;

export const ColorModifier = styled.span`
  color: ${theme.colors.blue};
`;

