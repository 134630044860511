import React from 'react';
import {
  Section,
  SmallText,
  Title,
  ContactForm,
  BulletItem,
  Container,
} from '@components';

import iconRounded from '@assets/icons/rounded-blue-element.svg';
import contactText from '@assets/icons/contact-text.svg';
import background from '@assets/imgs/background-contact.png';
import { contactData } from './settings';

import {
  Column,
  ImageContact,
  WidthModifier,
  PositionedBrand,
  FloatingRoundedElem,
} from './styles';

export const ContactSection = () => (
  <Section
    relative
    padding="regular"
    background={background}
    direction="column"
    full
    responsiveDirection
  >
    <ImageContact src={contactText} />
    <Column direction="column">
      <WidthModifier>
        <Title id="contactSection" weight="bold" bottom="2">
          Vamos conversar?
        </Title>
        <SmallText color="whiteMate" bottom="3">
          Estamos ansiosos para conhecer suas ideias. Preencha os campos e logo
          menos nossa equipe entrará em contato com você.
        </SmallText>
      </WidthModifier>
      <Container direction="column">
        {contactData.map(item => (
          <BulletItem
            key={`${item.title}-bullet`}
            image={item.icon}
            weight="bold"
            direction="row"
            outerPadding={'3 0'}
            innerPadding={'0 3'}
            title={item.title}
            small
          >
            <SmallText color="whiteMate" lineHeight="0.5">
              {item.text}
            </SmallText>
          </BulletItem>
        ))}
      </Container>
      <PositionedBrand color="whiteMate" lineHeight="0.5">
        © 2020 SevenApps. Todos direitos reservados.
      </PositionedBrand>
    </Column>
    <Column relative justify="center" margin={['4 0 0', '0']}>
      <FloatingRoundedElem src={iconRounded} />
      <ContactForm />
    </Column>
  </Section>
);
