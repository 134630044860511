import React from 'react';
import landingVideo from '@assets/videos/landing-video.mp4';
import { Section, ContactForm, Subtitle, MinorSubtitle } from '@components';

import iconRounded from '@assets/icons/rounded-blue-element.svg';
import background from '@assets/imgs/background-contact.png';
import { fixedTexts } from './settings';
import { Column, FloatingRoundedElem, Video } from './styles';

export const InitialSection = ({ text }) => (
  <Section background={background} direction="column" full responsiveDirection>
    <Column direction="column">
      <Subtitle weight="bold" bottom="3">
        {fixedTexts.title}
      </Subtitle>
      <Video controls autoPlay src={landingVideo} />
      <MinorSubtitle bottom="3">
        Tem dúvidas sobre desenvolvimento de APP ou um software para a sua
        empresa? <strong>Preencha o formulário ao lado</strong> para falar com
        um dos especialistas em desenvolvimento da Seven.
      </MinorSubtitle>
    </Column>
    <Column justify="center" margin={['4 0 0', '0']}>
      <FloatingRoundedElem src={iconRounded} />
      <ContactForm />
    </Column>
  </Section>
);
