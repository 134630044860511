export const bulletItems = [
  {
    title: 'Check-up e análise de caso.',
    content:
      'Junto com nossa equipe de especialistas, iremos levantar os requisitios de viabilidade de seu projeto.',
  },
  {
    title: 'Definição de casos de uso.',
    content:
      'Iremos separar cada funcionalidade de seu sistema em casos de uso, para um melhor planejamento.',
  },
  {
    title: 'Design e usabilidade (UX/UI).',
    content:
      'Criaremos protótipos interativos para você testar e validar as telas e usabilidade do seu sistema.',
  },
  {
    title: 'Implantação da solução e suporte.',
    content:
      'Desenvolvemos o código com nosso time e entregamos versões novas a cada semana. Ao final, prestamos suporte para melhorias.',
  },
];
