import iconSystem from '@assets/icons/icon-system.svg';
import iconMedal from '@assets/icons/icon-medal.svg';
import iconMobile from '@assets/icons/icon-mobile.svg';

export const bulletItems = [
  {
    icon: iconSystem,
    title: 'Criação de um sistema de ponta a ponta.',
    content:
      'Usamos JavaScript, TypeScript e seus respectivos frameworks, para garantir um código unificado e mais estável para aplicação final.',
  },
  {
    icon: iconMobile,
    title: 'Somos experts em desenvolvimento mobile.',
    content:
      'Nossa equipe é composta de especialistas em desenvolvimento mobile na tecnologia React Native. Nós somos experientes no assunto.',
  },
  {
    icon: iconMedal,
    title: 'Nosso processo é transparente.',
    content:
      'Através do nosso sistema de gerenciamento, clientes e gerentes podem acompanhar cada etapa do desenvolvimento de seu projeto.',
  },
];
