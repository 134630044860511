import styled from 'styled-components';
import { StyleContainer, SmallText } from '@components';
import { theme } from '@theme/theme';

export const Column = styled(StyleContainer)`
  width: 100%;
  position: ${({ relative }) => (relative ? 'relative' : 'static')};
  @media (min-width: ${theme.breakpoints.lg}) {
    width: 50%;
    position: static;
  }
`;

export const ImageContact = styled.img`
  position: absolute;
  top: 100px;
  left: 175px;
  display: none;
  @media (min-width: ${theme.breakpoints.lg}) {
    display: block;
  }
`;

export const WidthModifier = styled.span`
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: ${({ maxW }) => maxW || '350px'};
  }
`;

export const PositionedBrand = styled(SmallText)`
  position: absolute;
  bottom: 35px;
`;

export const FloatingRoundedElem = styled.img`
  position: absolute;
  top: 0;
  right: -7px;
  z-index: 0;
  max-width: calc(100vw - 10px);
  transform: rotate(90deg);
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: unset;
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    height: 550px;
    max-width: 450px;
    top: unset;
    bottom: 0;
    transform: rotate(180deg);
    right: unset;
  }
`;
