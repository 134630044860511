import React from 'react';
import { Section, SmallText, BulletItem } from '@components';

import firstSolutionsIcon from '@assets/icons/solutions-icon1.svg';
import secondSolutionsIcon from '@assets/icons/solutions-icon2.svg';
import background from '@assets/imgs/background-solutions.png';
import servicesText from '@assets/icons/services-text.svg';

import {
  Column,
  ColorModifier,
  CustomContainer,
  WidthModifier,
  CustomTitle,
  ImageServices,
} from './styles';

export const SolutionsSection = () => (
  <Section
    full
    backgroundLg={background}
    padding="regular"
    direction="column"
    position="right bottom"
    responsiveDirection
    size="contain"
  >
    <Column direction="column">
      <WidthModifier>
        <CustomTitle weight="semibold" bottom="3">
          Criamos soluções <ColorModifier>inovadoras</ColorModifier> em tempo
          recorde!
        </CustomTitle>
      </WidthModifier>
      <CustomContainer>
        <ImageServices src={servicesText} />
        <BulletItem
          image={firstSolutionsIcon}
          weight="bold"
          outerPadding={'3 3 0 0'}
          innerPadding={'3 0 0'}
          title="Desenvolvimento de soluções digitais"
        >
          <SmallText color="whiteMate" lineHeight="1.35">
            - Desenvolvimento de Sistemas <br />
            - Aplicativos Mobile
            <br />
            - Aplicativos Web
            <br />
            - API (back-end)
            <br />
            - Banco de Dados <br />- Cloud
          </SmallText>
        </BulletItem>
        <BulletItem
          image={secondSolutionsIcon}
          weight="bold"
          outerPadding={'3 3 0 0'}
          innerPadding={'3 0 0'}
          title="Serviço de consultoria especializada"
        >
          <SmallText color="whiteMate" lineHeight="1.35">
            Ajudamos a sua empresa e também sua equipe a estruturar e evoluir
            seu sistema atual, disponibilizando nossos especialistas nas
            tecnologias necessárias.
          </SmallText>
        </BulletItem>
      </CustomContainer>
    </Column>
  </Section>
);
