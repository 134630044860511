import styled from 'styled-components';
import { StyleContainer } from '@components';
import { theme } from '@theme/theme';

export const Column = styled(StyleContainer)`
  width: 100%;
  position: relative;
  @media (min-width: ${theme.breakpoints.lg}) {
    width: 50%;
    position: static;
  }
`;

export const FloatingRoundedElem = styled.img`
  position: absolute;
  top: 0;
  right: -7px;
  z-index: 0;
  max-width: calc(100vw - 30px);
  transform: rotate(90deg);
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: unset;
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    transform: rotate(0deg);
    right: unset;
    max-width: 450px;
  }
`;

export const CustomHolder = styled.div`
  margin-top: ${theme.spaces[3]};
  @media (min-width: ${theme.breakpoints.lg}) {
    margin-top: ${theme.spaces[0]};
  }
`;

export const Video = styled.video`
  width: 100%;
  margin-bottom: ${theme.spaces[3]};
`;
