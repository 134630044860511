import styled from 'styled-components';
import { StyleContainer, Title } from '@components';
import { theme } from '@theme/theme';

export const WidthModifier = styled.span`
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: ${({ maxW }) => maxW || '300px'};
  }
`;

export const ColorModifier = styled.span`
  color: ${theme.colors.blue};
`;

export const ImageDifferentials = styled.img`
  position: absolute;
  top: 25px;
  left: 200px;
  display: none;
  @media (min-width: ${theme.breakpoints.lg}) {
    display: block;
  }
`;

export const CustomContainer = styled(StyleContainer)`
  position: relative;
  align-items: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    align-items: flex-start;
  }
`;

export const CustomTitle = styled(Title)`
  text-align: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    text-align: start;
  }
`;

export const ContainerCarousel = styled(StyleContainer)`
  padding: ${`0px -${theme.spaces[4]}`};
  @media (min-width: ${theme.breakpoints.sm}) {
    padding: ${`0px -${theme.spaces[6]}`};
  }
`;
