import React from 'react';
import { Section, Container, PhoneItem } from '@components';

import background from '@assets/imgs/background-cases.png';
import casesText from '@assets/icons/cases-text.svg';
import { cases } from './settings';

import { ImageCases, CustomTitle } from './styles';

export const CasesSection = () => (
  <Section
    full
    direction="column"
    relative
    padding={['6 4 6', '9 6 0']}
    backgroundLg={background}
    position="top center"
    size="contain"
  >
    <ImageCases src={casesText} />
    <CustomTitle weight="bold" bottom="3">
      Nossos cases
    </CustomTitle>
    <Container
      margin={['2 0 0', '6 0 0']}
      direction="column"
      responsiveDirection
    >
      {cases.map(caseApp => (
        <PhoneItem key={caseApp.id} {...caseApp} />
      ))}
    </Container>
  </Section>
);
