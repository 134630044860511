import reactIcon from '@assets/icons/icon-react.png';
import gatsbyIcon from '@assets/icons/icon-gatsby.png';
import nextjsIcon from '@assets/icons/icon-next.png';
import htmlIcon from '@assets/icons/icon-html.png';
import cssIcon from '@assets/icons/icon-css.png';
import jsIcon from '@assets/icons/icon-js.png';
import tsIcon from '@assets/icons/icon-ts.png';
import nodejsIcon from '@assets/icons/icon-nodejs.png';
import nestjsIcon from '@assets/icons/icon-nestjs.png';
import graphqlIcon from '@assets/icons/icon-graphql.png';
import awsIcon from '@assets/icons/icon-aws.png';
import herokuIcon from '@assets/icons/icon-heroku.png';
import flutterIcon from '@assets/icons/icon-flutter.png';
import reactnativeIcon from '@assets/icons/icon-react-native.png';
import kotlinIcon from '@assets/icons/icon-kotlin.png';
import javaIcon from '@assets/icons/icon-java.png';
import swiftIcon from '@assets/icons/icon-swift.png';
import objectiveCIcon from '@assets/icons/icon-objectivec.png';

export const technologiesKeys = ['firstRow', 'secondRow', 'thirdRow'];

export const technologies = [
  [
    {
      icon: reactIcon,
      text: 'React',
    },
    {
      icon: gatsbyIcon,
      text: 'Gatbsy',
    },
    {
      icon: nextjsIcon,
      text: 'Next.js',
    },
    {
      icon: htmlIcon,
      text: 'HTML',
    },
    {
      icon: cssIcon,
      text: 'CSS',
    },
    {
      icon: jsIcon,
      text: 'JavaScript',
    },
  ],
  [
    {
      icon: tsIcon,
      text: 'TypeScript',
    },
    {
      icon: nodejsIcon,
      text: 'Node.js',
    },
    {
      icon: nestjsIcon,
      text: 'NestJS',
    },
    {
      icon: graphqlIcon,
      text: 'GraphQL',
    },
    {
      icon: awsIcon,
      text: 'Amazon AWS',
    },
    {
      icon: herokuIcon,
      text: 'Heroku',
    },
  ],
  [
    {
      icon: flutterIcon,
      text: 'Flutter',
    },
    {
      icon: reactnativeIcon,
      text: 'React Native',
    },
    {
      icon: kotlinIcon,
      text: 'Kotlin',
    },
    {
      icon: javaIcon,
      text: 'Java',
    },
    {
      icon: swiftIcon,
      text: 'Swift',
    },
    {
      icon: objectiveCIcon,
      text: 'Objective-C',
    },
  ],
];
