import { css } from 'styled-components';

const style = css`
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/Gilroy-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'GilroyLight';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/Gilroy-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'GilroyBold';
    font-style: bold;
    font-weight: 700;
    src: url('fonts/Gilroy-Bold.otf') format('opentype');
  }
`;

export default style;
