import iphoneAutiBooks from '@assets/icons/iphone-firstapp.svg';
import iphoneJurishand from '@assets/icons/iphone-secondapp.svg';
import iphoneBestBarbers from '@assets/icons/iphone-thirdapp.svg';
import autibooksLogo from '@assets/icons/autibooks-logo.png';
import jurishandLogo from '@assets/icons/logo-jurishand-white.svg';
import bestbarbersLogo from '@assets/icons/best-barbers-logo.png';

export const cases = [
  {
    id: 'autibooks',
    app: iphoneAutiBooks,
    text: 'Auti books',
    score: 4.5,
    logo: autibooksLogo,
  },
  {
    id: 'jurishand',
    app: iphoneJurishand,
    text: 'Jurishand',
    score: 4.4,
    logo: jurishandLogo,
  },
  {
    id: 'bestbarbers',
    app: iphoneBestBarbers,
    text: 'Bestbarbers',
    score: 4.8,
    logo: bestbarbersLogo,
  },
];
