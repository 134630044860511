import React from 'react';
import {
  Section,
  Container,
  BulletItem,
  SmallText,
  CarouselBar,
} from '@components';

import background from '@assets/imgs/background-steps.png';
import differentialsText from '@assets/icons/differentials-text.svg';
import { bulletItems } from './settings';

import {
  WidthModifier,
  ColorModifier,
  ImageDifferentials,
  CustomContainer,
  CustomTitle,
  ContainerCarousel,
} from './styles';

export const DifferentialsSection = () => (
  <Section
    full
    padding={['6 0 6', '7 0 0']}
    direction="column"
    backgroundLg={background}
    position="right bottom"
    justify="space-between"
    size="contain"
  >
    <CustomContainer padding={['0 4 6', '7 6 6']} direction="column">
      <ImageDifferentials src={differentialsText} />
      <WidthModifier>
        <CustomTitle weight="semibold" bottom="3">
          O que nos faz <ColorModifier>diferentes</ColorModifier>
        </CustomTitle>
      </WidthModifier>
      <Container margin={'5 0'} responsiveDirection direction="column">
        {bulletItems.map(item => (
          <BulletItem
            key={`${item.title}-bullet`}
            shadow
            image={item.icon}
            weight="bold"
            direction="row"
            outerPadding={'3 5 0 0'}
            innerPadding={'0 3'}
            title={item.title}
            small
          >
            <SmallText color="whiteMate" lineHeight="1.35">
              {item.content}
            </SmallText>
          </BulletItem>
        ))}
      </Container>
    </CustomContainer>
    <ContainerCarousel>
      <CarouselBar />
    </ContainerCarousel>
  </Section>
);
