import styled from 'styled-components';
import { HugeTitle } from '@components';
import { theme } from '@theme/theme';

export const ImageCases = styled.img`
  position: absolute;
  top: 85px;
  left: 30px;
  display: none;
  @media (min-width: ${theme.breakpoints.lg}) {
    display: block;
  }
`;

export const CustomTitle = styled(HugeTitle)`
  text-align: center;
  width: 100%;
  @media (min-width: ${theme.breakpoints.lg}) {
    text-align: start;
  }
`;
