import fontsStyles from './fonts';
import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

export const Global = createGlobalStyle`
  ${fontsStyles};
  * {

    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    border: 0;
    margin: 0;
    outline: 0;
    font-family: 'Gilroy', sans-serif;
  }

  #root {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }


  html {
    height: -webkit-fill-available;
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    font-size: 1.4rem;
    background-color: ${colors.darkPurple};
  }

  h1, h2, h3, h4, p {
    color: ${colors.white};
    margin: 0;
  }

  .hidden-body {
    overflow: hidden !important;
  }

  a:hover, a:visited, a:link, a:active
  {
    text-decoration: none;
  }

  button:focus, button:active,
  input:focus, input:active,
  div:focus, div:active,
  select:focus, select:active {
    outline-style: none;
  	outline:none;
  	outline: 0;
  }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }

  button::-moz-focus-inner {
    border: 0;
  }

  i {
    cursor: pointer;
  }
`;
