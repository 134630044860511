import React from 'react';
import { Section, Container, HugeTitle } from '@components';

import { technologies, technologiesKeys } from './settings';

import { WidthModifier, ColorModifier, ImageHolder, ImageText } from './styles';

export const TechnologiesSection = () => (
  <Section
    full
    padding={['6 4 6', '7 9 3']}
    direction="column"
    position="right bottom"
    size="contain"
  >
    <WidthModifier maxW="600px">
      <HugeTitle align="center" weight="semibold" bottom="3">
        Tecnologias que usamos<ColorModifier>.</ColorModifier>
      </HugeTitle>
    </WidthModifier>
    <Container margin="5 0 0" direction="row" responsiveDirection>
      {technologies.map((tech, key) => (
        <Container
          key={technologiesKeys[key]}
          margin="2 0"
          justify="space-between"
          responsiveDirection
          direction="column"
        >
          {tech.map(item => (
            <Container
              key={item.text}
              margin={['2 0', '3 0 0']}
              direction="column"
              align="center"
            >
              <ImageHolder>
                <img src={item.icon} alt={item.text} />
              </ImageHolder>
              <ImageText align="center">{item.text}</ImageText>
            </Container>
          ))}
        </Container>
      ))}
    </Container>
  </Section>
);
