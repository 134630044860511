import styled from 'styled-components';
import { CustomizeText } from '@components';
import { theme } from '@theme/theme';

export const WidthModifier = styled.span`
  max-width: ${({ maxW }) => maxW || '800px'};
  text-align: center;
`;

export const ColorModifier = styled.span`
  color: ${theme.colors.blue};
`;

export const ImageHolder = styled.div`
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 64px;
  height: 64px;
`;

export const ImageText = styled(CustomizeText)`
  font-size: ${theme.sizes[0]};
  margin-top: ${theme.spaces[2]};
`;
