import React from 'react';
import { Section, Container, HugeTitle, Text, Button } from '@components';
import { WidthModifier, ColorModifier } from './styles';

export const ThankYouSection = () => (
  <Section
    full
    padding={['6 4 6', '7 9 3']}
    direction="column"
    position="right bottom"
    size="contain"
  >
    <WidthModifier maxW="600px">
      <HugeTitle align="center" weight="semibold" bottom="3">
        Obrigado pelo seu contato<ColorModifier>!</ColorModifier>
      </HugeTitle>
    </WidthModifier>
    <Text weight="bold" margin="3">
        Em até 4 horas entraremos em contato.
      </Text>
      <a href="http://sevenapps.tech/">
        <Button text="Clique aqui para visitar o site da SevenApps." margin="6" />
      </a>

    
  </Section>
);
